<template>
  <div class="students">
    <!-- 1 -->
    <div class="title">Студентам</div>
    <!-- 2 -->
    <div class="bgimg"></div>
    <!-- 3 -->
    <div class="sec1">
      <div class="title">
        {{ sec1title }}
      </div>
      <div class="card1">{{ card1title }}</div>
      <div class="card2">{{ card2title }}</div>
      <div class="card3">{{ card3title }}</div>
      <div class="card4">{{ card4title }}</div>
      <div class="card5">
        {{ card5title }}
      </div>
    </div>
    <!-- 4 -->
    <div class="sec2">
      <div class="title">
        {{ sec2title }}
      </div>
      <div
        class="card1"
        style="display: grid; align-items: start; padding: 10px 0"
      >
        <p>{{ sec2card1title }}</p>
        <ul style="padding: 0 30px; font-weight: 300">
          <li v-for="sec2card1li in sec2card1list" :key="sec2card1li">
            {{ sec2card1li }}
          </li>
        </ul>
      </div>
      <div
        class="card2"
        style="display: grid; align-items: start; padding: 10px 0"
      >
        <p>{{ sec2card2title }}</p>
        <ul style="padding: 0 30px; font-weight: 300">
          <li v-for="sec2card2li in sec2card2list" :key="sec2card2li">
            {{ sec2card2li }}
          </li>
        </ul>
      </div>
      <div
        class="card3"
        style="display: grid; align-items: start; padding: 10px 0"
      >
        <p>{{ sec2card3title }}</p>
        <ul style="padding: 0 30px; font-weight: 300">
          <li v-for="sec2card3li in sec2card3list" :key="sec2card3li">
            {{ sec2card3li }}
          </li>
        </ul>
      </div>
      <img class="sec2bgl" src="../assets/students/sec2bgl.png" alt="" />
      <img class="sec2bgr" src="../assets/students/sec2bgr.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sec1title: "",
      card1title: "",
      card2title: "",
      card3title: "",
      card4title: "",
      card5title: "",
      sec2title: "",
      sec2card1title: "",
      sec2card1list: [],
      sec2card2title: "",
      sec2card2list: [],
      sec2card3title: "",
      sec2card3list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.sec1title = finalRes[44].text;
      this.card1title = finalRes[45].text;
      this.card2title = finalRes[46].text;
      this.card3title = finalRes[47].text;
      this.card4title = finalRes[48].text;
      this.card5title = finalRes[49].text;
      this.sec2title = finalRes[50].text;
      this.sec2card1title = finalRes[51].text;
      this.sec2card1list = finalRes[52].text.split(";");
      this.sec2card2title = finalRes[53].text;
      this.sec2card2list = finalRes[54].text.split(";");
      this.sec2card3title = finalRes[55].text;
      this.sec2card3list = finalRes[56].text.split(";");
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 416px) {
  .students {
    .title {
      font-weight: 600;
      font-size: 100px;
      line-height: 144px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/students/bg.png");
      background-size: cover;
      height: 538px;
    }
    .sec1 {
      display: grid;
      grid-template-columns:
        minmax(auto, 373px) minmax(auto, 373px) minmax(auto, 373px)
        minmax(auto, 373px) minmax(auto, 373px) minmax(auto, 373px);
      grid-template-rows: minmax(auto, 220px) repeat(2, 149px);
      gap: 30px;
      .title {
        font-weight: 500;
        font-size: 40px;
        line-height: 86%;
        grid-column: 1 / span 6;
        grid-row: 1;
        align-self: center;
      }
      .card1 {
        grid-row: 2;
        grid-column: 1 / span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 43px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card2 {
        grid-row: 2;
        grid-column: 3 / span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 43px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card3 {
        grid-row: 2;
        grid-column: 5 / span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 43px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card4 {
        grid-row: 3;
        grid-column: 2 / span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 43px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card5 {
        text-align: center;
        grid-row: 3;
        grid-column: 4 / span 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 43px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
    }
    .sec2 {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-template-rows: minmax(auto, 147px) 374px 180px;
      margin-top: 144px;
      .title {
        grid-column: 2 / span 2;
        grid-row: 1;
        font-family: Jost-Bold;
        font-weight: 500;
        font-size: 40px;
        line-height: 58px;
        text-transform: uppercase;
      }
      .card1 {
        grid-column: 2;
        grid-row: 2;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        width: 374px;
        height: 374px;
        background-color: white;
        box-shadow: 10px 10px 40px rgba(108, 108, 108, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card2 {
        grid-column: 3;
        grid-row: 2;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        width: 374px;
        height: 374px;
        background-color: white;
        box-shadow: 10px 10px 40px rgba(108, 108, 108, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card3 {
        grid-column: 4;
        grid-row: 2;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        width: 374px;
        height: 374px;
        background-color: white;
        box-shadow: 10px 10px 40px rgba(108, 108, 108, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sec2bgl {
        grid-column: 1 / span 2;
        grid-row: 2 / span 2;
        width: 460px;
        object-fit: cover;
        z-index: -1;
      }
      .sec2bgr {
        grid-column: 4 / span 2;
        grid-row: 1 / span 2;
        width: 460px;
        object-fit: cover;
        z-index: -1;
      }
    }
  }
}
@media (max-width: 415px) {
  .students {
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/students/bgm.png");
      background-size: cover;
      height: 516px;
    }
    .sec1 {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(6, minmax(90px, auto));
      gap: 30px;
      justify-content: center;
      margin-top: 60px;
      .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        grid-column: 1;
        grid-row: 1;
        align-self: center;
      }
      .card1 {
        grid-row: 2;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card2 {
        grid-row: 3;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card3 {
        grid-row: 4;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card4 {
        grid-row: 5;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card5 {
        grid-row: 6;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
    }
    .sec2 {
      display: grid;
      grid-template-columns: minmax(auto, 335px);
      grid-template-rows: repeat(4, auto);
      margin-top: 144px;
      row-gap: 20px;
      justify-content: center;
      .title {
        grid-column: 1;
        grid-row: 1;
        font-family: Jost-Bold;
        font-weight: 500;
        font-size: 32px;
        line-height: 46px;
        text-align: center;
        text-transform: uppercase;
      }
      .card1 {
        grid-column: 1;
        grid-row: 2;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        width: 374px;
        height: 374px;
        background-color: white;
        box-shadow: 10px 10px 40px rgba(108, 108, 108, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card2 {
        grid-column: 1;
        grid-row: 3;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        width: 374px;
        height: 374px;
        background-color: white;
        box-shadow: 10px 10px 40px rgba(108, 108, 108, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card3 {
        grid-column: 1;
        grid-row: 4;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        width: 374px;
        height: 374px;
        background-color: white;
        box-shadow: 10px 10px 40px rgba(108, 108, 108, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sec2bgl {
        display: none;
      }
      .sec2bgr {
        display: none;
      }
    }
  }
}
</style>
